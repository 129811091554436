@font-face {
    font-family: 'Byrd';
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/byrd-semibold-webfont.ttf") format('truetype');
}

@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/GraphikMedium.otf") format('truetype');
}

@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 300;
    src: url("./assets/fonts/GraphikLight.otf") format('truetype');
}

@font-face {
    font-family: 'Replica';
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/Replica Regular.otf") format('truetype');
}

@font-face {
    font-family: 'Replica';
    font-style: normal;
    font-weight: 300;
    src: url("./assets/fonts/Replica Light.otf") format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
