.active {
    text-decoration: underline !important;
}

.parentesi-menu{
    opacity: 0;
}

.testo-menu:hover .parentesi-menu{
    opacity: 1;
}